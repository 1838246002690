import React from 'react'
// import {Link} from 'gatsby'
import Layout from '../components/layout'
import Head from '../components/head'

const AboutPage= () => {
    return(
        <Layout>
        <Head title="About"/>    
        <h2>About</h2>
        <p>Making the transition from Java to Javascript. Enthusiastic about learning. In my spare time I read articles, blogs and explore new technologies. Currently work in higher eduction industry. 
        </p>
       
        </Layout>
        
    )
}
 export default AboutPage